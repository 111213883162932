import * as React from 'react';
import * as styles from './advisors.module.scss';

import linkedinImage from './images/linkedin.svg';
import twitterImage from './images/twitter.svg';

type Socials = {
  image: string;
  link: string;
};

const advisors = [
  {
    name: 'Charles Philips',
    socials: [
      {
        image: linkedinImage,
        link: 'https://www.linkedin.com/in/charlesphillips1',
      },
      {
        image: twitterImage,
        link: 'https://twitter.com/phillipsce',
      },
    ],
    details: [
      `CEO Infor`,
      `Former President of Oracle`,
      `Former Managing Director of the Technology Group at Morgan Stanley`,
      `Board Member of Viacom, Jazz at Lincoln Center, New York Law School, and the American Museum of Natural History`,
    ],
  },
  {
    name: 'Mohan Gyani',
    socials: [
      {
        image: linkedinImage,
        link: 'https://www.linkedin.com/in/mohan-gyani-2a455a2a',
      },
    ],
    details: [
      `Former President and CEO of AT&T Wireless`,
      `Vice Chairman of Mobileum`,
      `Former Board Member of Safeway, Union Bank`,
    ],
  },
  {
    name: 'Sharam Sasson',
    socials: [
      {
        image: linkedinImage,
        link: 'https://www.linkedin.com/in/sharam-sasson-a714b',
      },
    ],
    details: [
      `Founder and Chairman of Jitterbit`,
      `Former CEO and Chairman of Extensity`,
      `Co-Founder of Scopus Technology`,
      `Board Member of Kiverdi`,
    ],
  },
  {
    name: 'Bill Hague',
    socials: [
      {
        image: linkedinImage,
        link: 'https://www.linkedin.com/in/billhague',
      },
    ],
    details: [
      `Chairman of the GSMA Finance Committee`,
      `Former EVP Cingular Wireless`,
    ],
  },
  {
    name: 'Diane Greene',
    socials: [
      {
        image: linkedinImage,
        link: 'https://en.wikipedia.org/wiki/Diane_Greene',
      },
      // {
      //   image: twitterImage,
      //   link: 'https://twitter.com',
      // },
    ],
    details: [`Founder & Former CEO, VMware`, `Board Member, Alphabet`],
  },
  {
    name: 'Michael Cline',
    socials: [
      {
        image: linkedinImage,
        link: 'https://www.linkedin.com/in/jmichaelcline1',
      },
    ],
    details: [
      `Managing Partner, Accretive LLC`,
      `General Atlantic Partners`,
      `Founder, Fandango`,
    ],
  },
];

export default () => (
  <div className={styles.container}>
    <h2 className={styles.heading}>Advisors</h2>
    <div className={styles.advisorsContainer}>
      {advisors.map(advisor => (
        <div key={advisor.name} className={styles.advisor}>
          <div className={styles.socials}>
            {advisor.socials.map((socialDetails: Socials) => (
              <div key={socialDetails.link} className={styles.socialLink}>
                <a
                  href={socialDetails.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className={styles.image} src={socialDetails.image} />
                </a>
              </div>
            ))}
          </div>
          <p className={styles.name}>{advisor.name}</p>
          <ul className={styles.detailsContainer}>
            {advisor.details.map((detail: string, index: number) => (
              <li key={index} className={styles.detail}>
                {detail}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
);
