import * as React from 'react';
import * as styles from './teamPhotos.module.scss';

import Member from './Member';

import { OriOG, DhirajOG } from './images';

export type TeamMember = {
  name: string;
  title: string;
  image: () => JSX.Element;
  textContent: string[];
};

const teamMembers = [
  {
    name: 'Ori Sasson',
    title: 'General Partner',
    image: OriOG,
    textContent: [
      `Ori is the founding General Partner of Primera Capital and has one of the best investment records in the industry. He has chaired companies in the wireless carrier services, global transportation logistics and managed services spaces.`,
      `Past investments include GlobeTouch, Mobileum (formerly known as Roamware), GTNexus, C3, VMware, VXtreme (Acquired by Microsoft), Cramster (acquired by Chegg), Fandango (acquired by Comcast), Chegg (CHGG), Mobile Access Network (Acquired by Corning), NetDynamics (acquired by Sun Microsystems), Octane (Acquired by Epiphany), NewRoads(acquired by GSI Commerce) and Genesys (Nasdaq: GCTI).`,
      `Ori possesses extensive venture and hands-on business experience. Before founding Primera, Ori was a partner at Apax Partners. Prior to that, he served as President and CEO of Genesys Telecommunications Laboratories, where he expanded the company's global market reach, recruited a top-notch international management team, and managed the company's acquisition by Paris-based Alcatel in the year 2000.`,
      `Ori also served as Chairman and CEO of Scopus Technology - a company he cofounded in 1991 - and directed, from its inception to a highly successful IPO and merger with Siebel Systems in April 1998, creating the second largest applications company in the world. Ori has used his business acumen to advise many young companies and has received numerous accolades, including the Bay Area Entrepreneur of the Year, 1997.`,
      `Ori holds an MS in Operations Research and a BA in Computer Science from the University of California at Berkeley. He sits on the boards of GlobeTouch, Jitterbit, Kiverdi and the Children's Hospital of Oakland.`,
    ],
  },
  {
    name: 'Dhiraj Shah',
    title: 'General Partner',
    image: DhirajOG,
    textContent: [
      `Dhiraj Shah is an entrepreneur, transformation leader, and general partner at Primera Capital. Prior to joining Primera Capital, Dhiraj served as founder, president, and CEO of Avaap from 2006 to 2021. He led the organization to year-over year growth driven by strong culture, exceptional consultants, high performance, and customer success.`,
      `The growth and recognition are testament to Dhiraj’s vision on how to scale and improve customer business practices through strategic partnerships, exceptional software services, and retaining top talent by providing employees with a great place to work. While at the helm Dhiraj led the charge on two innovation-driven acquisitions, a healthcare EHR consultancy and three years later, a management consulting firm specializing in cloud transformation, leading to successful exit and monetization.`,
      `Dhiraj continues to serve as executive chairman on the Avaap board, board chair for the YPO New Jersey Chapter, and is on the board of trustees at the New Jersey Institute of Technology (NJIT). In addition to professional responsibilities, Dhiraj prioritizes living with purpose and driving positive change that impacts individuals, communities, and businesses globally.`,
      `Prior to founding Avaap, Dhiraj held several positions at Lawson Software and was instrumental in developing the company’s offshore strategy by helping establish a global center of excellence in Chennai. He has spoken at numerous industry conferences including CHIME Fall Forum, Inforum, INC. 500 conference, and at the Kaplan Institute.`,
      `Dhiraj provides businesses and founders access to expertise, partners, innovation, and a network of other entrepreneurs.  Dhiraj graduated with honors from New Jersey Institute of Technology with a B.S. in Computer Science and a minor in professional communications.`,
    ],
  },
  // {
  //   name: 'Greg Johnsen',
  //   title: 'Operating Partner',
  //   image: GregOG,
  //   textContent: [
  //     `Greg Johnsen is a Silicon Valley veteran with more than twenty-five years of sales, marketing and product management experience, and is currently the CEO of LifeLink. He was co-founder and chief marketing officer at GT Nexus, where he led the company's global marketing and business development operations until 2015, when the company was acquired by Infor. `,
  //     `Prior to GT Nexus he was with Scopus Technology, an early leader in the CRM. Before joining Scopus, he held regional sales roles at Neuron Data, an expert systems technology company. He began his career at Ingres Corporation, a pioneer in relational database management systems. `,
  //     `Mr. Johnsen has a Bachelor of Arts degree in English from the University of California at Davis.`,
  //   ],
  // },
  // {
  //   name: 'Nathan Levin',
  //   title: 'Senior Associate',
  //   image: NathanOG,
  //   textContent: [
  //     `Nathan works on all aspects of the investment process at Primera Capital, including deal sourcing, due diligence, industry research and interfacing with portfolio companies.`,
  //     `Prior to joining Primera, Nathan was Director of Product Management at MIT professor Sandy Pentland’s personal data privacy startup, You Technology. He has experience as a strategy analyst at Black Lotus Communication (acquired by Level3) and as an associate product manager working on database systems and mobile app development at Emotive (acquired by Appconomy). He began his career in the venture industry helping with startup investments at Siemens Technology-To-Business, the early-stage strategic investment arm of Siemens AG located in Berkeley, California.`,
  //     `Nathan holds a Masters degree in History from the University of Oxford and a BA in International Studies from Johns Hopkins University.`,
  //   ],
  // },
];

interface Props {}
interface State {
  members: TeamMember[];
  activeMember: TeamMember | null;
}

export default class TeamPhotos extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      members: teamMembers,
      activeMember: null,
    };
  }

  setActiveMember = (index: number | null) => {
    this.setState({
      activeMember: index !== null ? this.state.members[index] : null,
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <h2 className={styles.heading}>Investment Team</h2>
        <div className={styles.photosContainer}>
          {teamMembers.map((member, index) => {
            const OGImage = member.image;
            return (
              <div
                key={member.name}
                onClick={() => this.setActiveMember(index)}
                className={styles.memberContainer}
              >
                <OGImage />
                <div className={styles.contentContainer}>
                  <span className={styles.name}>{member.name}</span>
                  <span className={styles.position}>{member.title}</span>
                </div>
              </div>
            );
          })}
        </div>
        <Member
          closed={() => this.setActiveMember(null)}
          activeMember={this.state.activeMember}
        />
      </div>
    );
  }
}
