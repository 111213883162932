import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const imageQuery = () =>
  useStaticQuery(graphql`
    query {
      aaronOG: file(relativePath: { eq: "team/aaron_og.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      gregOG: file(relativePath: { eq: "team/greg_og.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nathanOG: file(relativePath: { eq: "team/nathan_og.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      oriOG: file(relativePath: { eq: "team/ori_og.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      dhirajOG: file(relativePath: { eq: "team/dhiraj_shah_og.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      aaronBW: file(relativePath: { eq: "team/aaron_og.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, grayscale: true) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      gregBW: file(relativePath: { eq: "team/greg_og.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, grayscale: true) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nathanBW: file(relativePath: { eq: "team/nathan_og.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, grayscale: true) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      oriBW: file(relativePath: { eq: "team/ori_og.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, grayscale: true) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      dhirajBW: file(relativePath: { eq: "team/dhiraj_shah_og.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, grayscale: true) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

export const AaronOG = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.aaronOG.childImageSharp.fluid} />;
};
export const OriOG = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.oriOG.childImageSharp.fluid} />;
};
export const GregOG = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.gregOG.childImageSharp.fluid} />;
};
export const NathanOG = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.nathanOG.childImageSharp.fluid} />;
};
export const DhirajOG = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.dhirajOG.childImageSharp.fluid} />;
};
