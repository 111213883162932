import * as React from 'react';
import * as styles from './member.module.scss';
import classnames from 'classnames';

import { TeamMember } from '../TeamPhotos';
import closeImage from '../../../../images/close.svg';

export default ({
  activeMember,
  closed,
}: {
  activeMember: TeamMember | null;
  closed: () => void;
}) => {
  if (activeMember) {
    const Image = activeMember.image;
    return (
      <div
        className={classnames(
          styles.container,
          activeMember ? styles.visible : '',
        )}
      >
        <div onClick={() => closed()} className={styles.close}>
          <img src={closeImage} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <div className={styles.image}>
              <Image />
            </div>
            <div className={styles.details}>
              <span className={styles.name}>{activeMember.name}</span>
              <span className={styles.position}>{activeMember.title}</span>
              <span className={styles.subPosition}>Investment Team</span>
            </div>
          </div>
          <div className={styles.textContent}>
            {activeMember.textContent.map((paragraph, index) => (
              <p key={index} className={styles.paragraph}>
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
};
