import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import BasicPage from '../templates/BasicPage';
import Title from '../components/shared/Title';
import TeamPhotos from '../components/team/TeamPhotos';
import Advisors from '../components/team/Advisors';

const topTitle = `Team`;
const bylines = [
  `We are here to turn the impractical into the actual, the nonconformist into the convention, and the experimental into the fundamental.`,
  `We are here to build extraordinary businesses.`,
];

const TeamPage = () => (
  <Layout>
    <SEO pageTitle="Team" description="" />
    <BasicPage>
      <Title title={topTitle} bylines={bylines} />
      <TeamPhotos />
      <Advisors />
    </BasicPage>
  </Layout>
);

export default TeamPage;
