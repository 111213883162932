import * as React from 'react';
import * as styles from './title.module.scss';

import classnames from 'classnames';

export default ({
  title,
  bylines,
  light,
}: {
  title: string;
  bylines: string[];
  light?: boolean;
}) => (
  <div className={classnames(styles.container, light ? styles.light : '')}>
    <h2 className={styles.topTitle}>{title}</h2>
    {bylines.map((byline, index) => (
      <h4 key={index} className={styles.byline}>
        {byline}
      </h4>
    ))}
  </div>
);
