import * as React from 'react';
import * as styles from './basicPage.module.scss';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import classnames from 'classnames';

export default ({
  children,
  background,
  light,
}: {
  children: any;
  background?: boolean;
  light?: boolean;
}) => (
  <div
    className={classnames(
      styles.pageContainer,
      light ? styles.light : styles.dark,
    )}
  >
    <Header background={background} light={light} />
    {children}
    <Footer light={light} />
  </div>
);
